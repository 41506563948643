import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeComp from "./Component/HomeComp";
import DynamicRoutes from "./Component/DynamicRoutes";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeComp />} />
          <Route path="/:coupon" element={<DynamicRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
